/** @jsx jsx */
import { jsx } from 'theme-ui'

type ContentProps = {
  children: React.ReactNode
  className?: string
}

const Content = ({ children, className = `` }: ContentProps) => (
  <div
    sx={{
      position: 'absolute',
      inset: '0px',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
      willChange: 'transform',
      width: '100%',
      height: '999px',
      transform: 'translate3d(0px, 0px, 0px)',
      padding: [3, 4, 4, 5],
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      justifyContent: `center`,
      zIndex: 50,
    }}
    className={className}
  >
    {children}
  </div>
)

export default Content
